@media only screen and (min-width: 992px) {
  .container {
    max-width: 872px; } }

body {
  margin: 0px; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  max-width: 1400px;
  margin: 0 auto; }
  .header .image-container {
    cursor: pointer; }
    .header .image-container img {
      width: 100px;
      height: 100px; }
  .header.login-button-hamburger-style {
    transform: translate(35%, 60%); }

@media only screen and (min-width: 992px) {
  .header {
    display: relative;
    align-items: none;
    justify-content: none; }
    .header .image-container {
      position: absolute;
      margin-left: 7%; }
    .header img {
      width: 110px;
      height: 110px; }
    .header.login-button-hamburger-style {
      transform: none; } }

.hamburger-lines {
  height: 50px;
  float: right;
  margin-right: 15%; }
  .hamburger-lines .line {
    height: 6px;
    width: 55px;
    border-radius: 10px;
    background-color: #622b62;
    margin: 8px 0; }
  .hamburger-lines.show-close .line1 {
    transform: rotate(32deg); }
  .hamburger-lines.show-close .line2 {
    transform: scaleY(0); }
  .hamburger-lines.show-close .line3 {
    transform: rotate(-32deg); }
  .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.6s ease-in-out; }
  .hamburger-lines .line2 {
    transition: transform 0.5s ease-in-out; }
  .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.6s ease-in-out; }

@media only screen and (min-width: 992px) {
  .hamburger-lines {
    display: none; } }

.nav-buttons-menu-container {
  position: absolute;
  z-index: 20;
  transform: translate(0%, -80%);
  transition: transform 0.5s ease-in-out;
  height: 400px;
  width: 100%;
  background-color: white; }
  .nav-buttons-menu-container.show-menu {
    transform: translate(0%, 60%); }
  .nav-buttons-menu-container li {
    list-style: none;
    margin: 25px; }
  .nav-buttons-menu-container .header-buttons {
    border: white;
    color: black;
    background-color: transparent;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    width: 100%;
    border-bottom: 2px solid white;
    border-top: 2px solid white; }
  .nav-buttons-menu-container .header-buttons:hover {
    border-bottom: 2px solid #622b62;
    border-top: 2px solid #622b62; }
  @media only screen and (min-width: 992px) {
    .nav-buttons-menu-container {
      position: relative;
      transform: none;
      transition: none;
      height: auto;
      width: auto;
      margin: 0 auto; }
      .nav-buttons-menu-container.show-menu {
        transform: none; }
      .nav-buttons-menu-container li {
        margin: 10px;
        display: inline-block; }
      .nav-buttons-menu-container .header-buttons {
        padding: 10px; }
        .nav-buttons-menu-container .header-buttons:before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden; }
        .nav-buttons-menu-container .header-buttons:hover {
          color: #622b62;
          font-weight: bold;
          cursor: pointer; } }

.login-button-container {
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex; }
  .login-button-container .login-button {
    background-color: #622b62;
    border-radius: 5px;
    width: 180px;
    height: 60px;
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: white;
    border: 0px; }
  .login-button-container .login-button:hover {
    background-color: #8e3e8e;
    cursor: pointer; }

.login-button-hamburger-style {
  z-index: 1; }

@media only screen and (min-width: 992px) {
  .login-button-container {
    position: absolute;
    display: inline-block;
    margin-left: 5%; }
    .login-button-container .login-button {
      width: 140px;
      height: 50px;
      margin-top: 5px; } }

.page-intro-container {
  width: 100%;
  height: auto;
  background-color: #1e021e;
  position: flex;
  color: white; }
  .page-intro-container .title-container {
    width: 360px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: center; }
    .page-intro-container .title-container .lis-nails-title {
      font-family: "Inter", sans-serif;
      font-size: 14px; }
    .page-intro-container .title-container .page-title {
      font-family: "Inter", sans-serif;
      margin-top: 5px;
      font-size: 26px;
      font-weight: 700; }
  .page-intro-container .intro-container {
    margin: 0 auto;
    margin-top: 40px;
    width: 360px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    text-align: center; }
  .page-intro-container .buttons-container {
    width: 280px;
    margin: 0 auto;
    margin-top: 30px; }
    .page-intro-container .buttons-container .book-now-button {
      font-family: "Inter", sans-serif;
      border: 0;
      background-color: #622b62;
      border-radius: 2px;
      width: 130px;
      height: 40px;
      color: white; }
      .page-intro-container .buttons-container .book-now-button:hover {
        background-color: #8e3e8e;
        cursor: pointer; }
    .page-intro-container .buttons-container .faq-button {
      font-family: "Inter", sans-serif;
      border: 0;
      background-color: white;
      border-radius: 2px;
      width: 130px;
      height: 40px;
      float: right;
      color: black; }
      .page-intro-container .buttons-container .faq-button:hover {
        background-color: #622b62;
        cursor: pointer;
        color: white; }
  .page-intro-container .page-intro-image-container {
    width: 280px;
    margin: 0 auto;
    margin-top: 35px;
    padding-bottom: 30px; }
    .page-intro-container .page-intro-image-container .page-intro-img {
      width: 280px;
      height: 280px; }
  @media only screen and (min-width: 992px) {
    .page-intro-container {
      margin: 0 auto;
      padding-top: 5%;
      overflow: hidden; }
      .page-intro-container .center-content {
        width: 1000px;
        margin: 0 auto; }
      .page-intro-container .content-container {
        width: 500px;
        height: auto;
        float: left; }
      .page-intro-container .title-container {
        margin-left: 10%;
        text-align: left; }
      .page-intro-container .intro-container {
        text-align: left;
        margin-left: 10%;
        width: auto; }
      .page-intro-container .buttons-container {
        margin-left: 10%;
        margin-top: 8%; }
      .page-intro-container .page-intro-image-container {
        margin: 0;
        float: left;
        margin-left: 10%; }
        .page-intro-container .page-intro-image-container .page-intro-img {
          width: 360px;
          height: 360px; } }

.content-card-container {
  margin-top: 15%;
  height: auto;
  text-align: center; }
  .content-card-container .content-card-title {
    font-family: "Playfair Display";
    font-size: 24px;
    font-weight: 700; }
  .content-card-container .content-card-paragraph {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #676C70;
    margin-left: 5%;
    margin-right: 5%; }
  .content-card-container .content-card-image-container {
    margin-top: 10%; }
    .content-card-container .content-card-image-container .content-card-image {
      height: 340px;
      width: 360px; }
  .content-card-container .bg-screen-dots {
    display: none; }
  .content-card-container .no-display-dots {
    display: none; }
  .content-card-container .dots-container {
    margin-top: 15%; }
    .content-card-container .dots-container .dot {
      height: 15px;
      width: 15px;
      background-color: #622b62;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5%; }
  .content-card-container ul {
    text-align: left;
    list-style: none; }
    .content-card-container ul .circle-checkmark {
      font-family: sans-serif;
      background: #622b62;
      color: #ffffff;
      padding: 1px 4px;
      border-radius: 50%;
      margin-right: 10px; }
  .content-card-container li {
    margin: 24px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #676C70;
    margin-left: 0px; }
  @media only screen and (min-width: 992px) {
    .content-card-container {
      max-width: 900px;
      align-items: center;
      display: flex;
      padding-top: 3%;
      margin: 0 auto; }
      .content-card-container .text-container {
        width: 40%;
        border-left: solid;
        border-left-width: 5px;
        border-left-color: #622b62;
        margin-right: 10%; }
      .content-card-container .content-card-image-container {
        width: fit-content;
        margin: 0 auto;
        margin-left: 0px; }
      .content-card-container .sm-screen-dots {
        display: none; }
      .content-card-container .bg-screen-dots {
        display: block; }
      .content-card-container .safety-list-container {
        max-width: 500px; } }

.reverse-row {
  flex-direction: row-reverse; }

.footer-container {
  margin-top: 5%; }
  .footer-container .footer-logo-message-container {
    text-align: center; }
    .footer-container .footer-logo-message-container .footer-logo-img {
      width: 50px;
      height: 50px; }
    .footer-container .footer-logo-message-container .footer-intro-message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #676C70; }
    .footer-container .footer-logo-message-container .footer-social-media-container .footer-logo-img {
      height: 40px;
      width: 40px;
      margin: 10px;
      cursor: pointer; }
  .footer-container .footer-links-container {
    text-align: left; }
    .footer-container .footer-links-container h3 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px; }
    .footer-container .footer-links-container label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #676C70;
      text-decoration: none;
      display: list-item;
      list-style: none;
      margin: 0px 0px 15px 0px;
      cursor: pointer; }
    .footer-container .footer-links-container a {
      text-decoration: none; }
    .footer-container .footer-links-container .product-container {
      display: inline-block;
      width: 50%;
      margin-left: 5%; }
    .footer-container .footer-links-container .about-us-container {
      width: 40%;
      display: inline-block;
      margin-left: 5%; }
  @media only screen and (min-width: 992px) {
    .footer-container {
      margin: 0 auto;
      max-width: 1400px;
      display: flex;
      margin-top: 2%; }
      .footer-container .footer-logo-message-container {
        text-align: left;
        width: 400px;
        margin-left: 5%;
        margin: 0 auto; }
      .footer-container .footer-links-container {
        width: 55%; }
        .footer-container .footer-links-container .product-container {
          width: 40%;
          margin-left: 5%; }
        .footer-container .footer-links-container .about-us-container {
          margin-left: 5%; } }

.copy-right-footer-container {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  color: #676C70; }
  .copy-right-footer-container .copy-right-message {
    float: left;
    padding-left: 5%; }
  .copy-right-footer-container .terms-and-conditions-message {
    float: right;
    padding-right: 5%; }

.splash-box-container {
  background-color: #622b62;
  width: 360px;
  height: 90px;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden; }
  .splash-box-container .circle {
    width: 40px;
    height: 40px;
    background-color: #8e3e8e; }
  .splash-box-container .circle-one {
    border-radius: 50%;
    transform: translate(-5px, -50%); }
  .splash-box-container .circle-two {
    border-radius: 50%;
    transform: translate(450%, 75%); }
  .splash-box-container .circle-three {
    width: 40px;
    height: 20px;
    background-color: #8e3e8e;
    border-radius: 50px 50px 0 0;
    transform: translate(650%, -440%) rotate(-35deg); }
  .splash-box-container .splash-box-message {
    width: 190px;
    margin-left: 5%;
    margin-top: -25%; }
    .splash-box-container .splash-box-message h3 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: white; }
    .splash-box-container .splash-box-message p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: white; }
  .splash-box-container .splash-box-button-container {
    margin-left: 75%;
    margin-top: -14%; }
    .splash-box-container .splash-box-button-container button {
      cursor: pointer;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      text-align: center;
      border: 0px;
      border-radius: 5px;
      height: 30px;
      background-color: white;
      color: #ef5da8; }
  @media only screen and (min-width: 992px) {
    .splash-box-container {
      width: 950px;
      height: 140px;
      margin-top: 5%; }
      .splash-box-container .circle {
        width: 80px;
        height: 80px;
        background-color: #8e3e8e; }
      .splash-box-container .circle-one {
        border-radius: 50%;
        transform: translate(-18px, -55%); }
      .splash-box-container .circle-two {
        border-radius: 50%;
        transform: translate(550%, 25%); }
      .splash-box-container .circle-three {
        width: 80px;
        height: 40px;
        background-color: #8e3e8e;
        border-radius: 50px 50px 0 0;
        transform: translate(850%, -440%) rotate(-35deg); }
      .splash-box-container .splash-box-message {
        width: 250px;
        margin-left: 10%;
        margin-top: -22%; }
        .splash-box-container .splash-box-message h3 {
          font-size: 22px;
          line-height: 24px; }
        .splash-box-container .splash-box-message p {
          font-size: 12px;
          line-height: 14px;
          color: white; }
      .splash-box-container .splash-box-button-container {
        margin-left: 80%;
        margin-top: -9%; }
        .splash-box-container .splash-box-button-container button {
          font-size: 14px;
          height: 50px; }
          .splash-box-container .splash-box-button-container button:hover {
            background-color: #8e3e8e;
            color: white; } }

.cancel-appt-form-container {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%; }
  .cancel-appt-form-container h3 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px; }
  .cancel-appt-form-container p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #676C70; }
  .cancel-appt-form-container .cancel-appt-fields-container {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .cancel-appt-form-container .cancel-appt-fields-container .confirmation-error-container .confirmation-error {
      color: #EB5757;
      font-size: 14px; }
    .cancel-appt-form-container .cancel-appt-fields-container label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 48px; }
    .cancel-appt-form-container .cancel-appt-fields-container input {
      height: 41px;
      width: 100%;
      border: 1px solid #b1b5c3;
      border-radius: 5px;
      font-size: 18px;
      padding-left: 15px; }
      .cancel-appt-form-container .cancel-appt-fields-container input:focus {
        outline-color: #622b62; }
      .cancel-appt-form-container .cancel-appt-fields-container input::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #b1b5c4; }
  .cancel-appt-form-container button {
    margin-top: 5%;
    background-color: #622b62;
    border-radius: 5px;
    border: 0px;
    height: 55px;
    width: 200px;
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff; }
    .cancel-appt-form-container button:hover {
      background-color: #8e3e8e;
      cursor: pointer; }
    .cancel-appt-form-container button:disabled {
      background-color: #676C70;
      cursor: default; }
  @media only screen and (min-width: 992px) {
    .cancel-appt-form-container {
      max-width: 1000px;
      margin: 0 auto; }
      .cancel-appt-form-container .text-container {
        margin-left: 5%;
        border-left: 6px solid #622b62;
        text-align: left;
        width: 60%;
        padding-left: 3%; }
        .cancel-appt-form-container .text-container h3 {
          font-size: 38px;
          line-height: 60px; }
      .cancel-appt-form-container .cancel-appt-fields-container {
        margin-top: 3%;
        margin-left: 5%;
        display: flex;
        justify-content: space-between; }
        .cancel-appt-form-container .cancel-appt-fields-container input {
          width: 100%; }
        .cancel-appt-form-container .cancel-appt-fields-container .email-container {
          width: 40%; }
        .cancel-appt-form-container .cancel-appt-fields-container .confirmation-number-container {
          width: 40%; }
        .cancel-appt-form-container .cancel-appt-fields-container label::after {
          content: "\a";
          white-space: pre; } }

.faq-container {
  margin-top: 15%;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%; }
  .faq-container h2 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    line-height: 34px; }
  .faq-container p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #676C70; }
  .faq-container img {
    width: 16px;
    height: 16px; }
  .faq-container .back-link {
    margin-left: 1%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #676C70;
    text-decoration: underline;
    cursor: pointer; }
  @media only screen and (min-width: 992px) {
    .faq-container {
      max-width: 1000px;
      margin: 0 auto;
      margin-top: 10%; }
      .faq-container .text-container {
        border-left: 6px solid #622b62;
        margin-left: 5%;
        width: 60%;
        text-align: left;
        padding-left: 5%; }
        .faq-container .text-container h2 {
          font-size: 38px;
          line-height: 60px; } }

.question-card-container {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  text-align: left;
  padding-bottom: 5%;
  margin-top: 5%; }
  .question-card-container h3 {
    padding-top: 5%;
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: black;
    margin-left: 5%; }
  .question-card-container p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #676C70;
    margin-left: 5%;
    margin-right: 5%; }

.purple-background {
  background-color: #1e021e;
  border: 0px; }
  .purple-background h3 {
    color: white; }
  .purple-background p {
    color: white; }

@media only screen and (min-width: 992px) {
  .question-card-container {
    margin: 0 auto;
    width: 80%;
    margin-top: 3%; } }

.login-container {
  overflow: hidden;
  border-radius: 10px; }
  .login-container .left-image-container {
    display: none; }
  .login-container input {
    height: 41px;
    border-radius: 5px;
    font-size: 18px; }
    .login-container input:focus {
      outline-color: #622b62; }
    .login-container input::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #b1b5c4; }
  .login-container .login-form-container {
    width: 80%;
    margin: 0 auto; }
    .login-container .login-form-container .login-title-container {
      margin: 0 auto;
      display: flex;
      margin-top: 10%; }
      .login-container .login-form-container .login-title-container label {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 700;
        font-size: 27px; }
      .login-container .login-form-container .login-title-container .login-icon-container {
        width: fit-content;
        margin-left: 5%;
        margin-top: 2%; }
        .login-container .login-form-container .login-title-container .login-icon-container img {
          width: 33px;
          height: 33px; }
    .login-container .login-form-container .email-container .email-error {
      color: #EB5757;
      font-size: 12px; }
    .login-container .login-form-container .email-container .email-input-container {
      width: 100%;
      background-color: white;
      border-radius: 5px;
      border: 2px solid #b1b5c3; }
      .login-container .login-form-container .email-container .email-input-container .email-input {
        outline: 0px;
        border: 0px;
        padding-left: 15px;
        width: 92%; }
      .login-container .login-form-container .email-container .email-input-container:focus-within {
        border: 2px solid #622b62; }
    .login-container .login-form-container label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 48px;
      letter-spacing: -0.01em; }
    .login-container .login-form-container input:-webkit-autofill,
    .login-container .login-form-container textarea:-webkit-autofill,
    .login-container .login-form-container select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-text-fill-color: black !important; }
    .login-container .login-form-container button {
      background-color: #622b62;
      border-radius: 5px;
      width: 100%;
      height: 50px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: white;
      border: 0px; }
      .login-container .login-form-container button:hover {
        background-color: #8e3e8e;
        cursor: pointer; }
    .login-container .login-form-container h2 {
      color: #676C70;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #676C70;
      line-height: 0.1em;
      margin: 20px 0 20px;
      font-size: 18px; }
    .login-container .login-form-container h2 span {
      background: #fff;
      padding: 0 10px; }
    .login-container .login-form-container .create-account-button {
      margin-top: 10%;
      background-color: transparent;
      border: 1px solid #622b62;
      margin-top: 0px;
      color: #622b62;
      cursor: pointer; }
      .login-container .login-form-container .create-account-button:hover {
        background-color: white; }
    .login-container .login-form-container .password-container {
      display: flex;
      flex-direction: column; }
      .login-container .login-form-container .password-container .input-password-container {
        width: 100%;
        border: 2px solid #b1b5c3;
        background-color: white;
        border-radius: 5px;
        height: 41px; }
        .login-container .login-form-container .password-container .input-password-container:focus-within {
          border: 2px solid #622b62; }
        .login-container .login-form-container .password-container .input-password-container .eye-image {
          width: 30px;
          height: 30px;
          float: right;
          padding-right: 10px;
          padding-top: 5px;
          cursor: pointer; }
        .login-container .login-form-container .password-container .input-password-container .password-input {
          width: 80%;
          border: 0px;
          height: 38px;
          padding-left: 15px; }
          .login-container .login-form-container .password-container .input-password-container .password-input:focus {
            outline: 0px; }
      .login-container .login-form-container .password-container .forgot-link-container {
        text-align: right;
        margin-top: 20px; }
        .login-container .login-form-container .password-container .forgot-link-container .forgot-password-link {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #676C70;
          text-decoration: underline;
          cursor: pointer;
          width: fit-content; }
      .login-container .login-form-container .password-container .resend-email {
        color: #EB5757; }
      .login-container .login-form-container .password-container .login-buttons-container {
        margin-top: 10%; }
  @media only screen and (min-width: 992px) {
    .login-container {
      width: 1000px;
      height: 600px;
      margin: 0 auto;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      margin-top: 5%;
      margin-bottom: 5%;
      box-shadow: 1rem 1rem 1rem rgba(51, 51, 51, 0.2); }
      .login-container .left-image-container {
        display: block;
        float: left;
        height: 100%;
        width: 45%; }
        .login-container .left-image-container img {
          width: 100%;
          height: 100%; }
      .login-container .login-form-container {
        float: right;
        width: 55%;
        margin: 0; }
        .login-container .login-form-container .login-title-container {
          width: 60%; }
        .login-container .login-form-container .email-container {
          margin: 0 auto;
          width: 60%; }
        .login-container .login-form-container .password-container {
          margin: 0 auto;
          width: 60%; }
          .login-container .login-form-container .password-container h2 span {
            background: #f2f2f2;
            padding: 0 10px; } }

.create-acct-container {
  overflow: hidden;
  border-radius: 10px; }
  .create-acct-container .create-acct-image-container {
    display: none; }
  .create-acct-container .create-acct-form-container {
    text-align: left;
    width: 80%;
    margin: 0 auto; }
    .create-acct-container .create-acct-form-container .contact-container .email-container .email-error-container .email-error {
      color: #EB5757;
      font-size: 12px; }
    .create-acct-container .create-acct-form-container .contact-container .phone-number-container .phone-error-container .phone-error {
      color: #EB5757;
      font-size: 12px; }
    .create-acct-container .create-acct-form-container .confirm-password-container .password-error-container .password-error {
      color: #EB5757;
      font-size: 12px; }
    .create-acct-container .create-acct-form-container .create-acct-button .form-error-container .form-error {
      color: #EB5757;
      font-size: 12px; }
    .create-acct-container .create-acct-form-container h2 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      line-height: 36px; }
    .create-acct-container .create-acct-form-container label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 48px;
      letter-spacing: -0.01em; }
    .create-acct-container .create-acct-form-container input {
      height: 41px;
      width: 100%;
      border: 1px solid #b1b5c3;
      border-radius: 5px;
      font-size: 18px;
      padding-left: 15px; }
      .create-acct-container .create-acct-form-container input:focus {
        outline-color: #622b62; }
      .create-acct-container .create-acct-form-container input::placeholder {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #b1b5c4; }
    .create-acct-container .create-acct-form-container button {
      margin-top: 10%;
      background-color: #622b62;
      border-radius: 5px;
      width: 100%;
      height: 50px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: white;
      border: 0px; }
      .create-acct-container .create-acct-form-container button:hover {
        background-color: #8e3e8e;
        cursor: pointer; }
  @media only screen and (min-width: 992px) {
    .create-acct-container {
      width: 1000px;
      height: 650px;
      margin: 0 auto;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      margin-top: 5%;
      margin-bottom: 5%;
      box-shadow: 1rem 1rem 1rem rgba(51, 51, 51, 0.2); }
      .create-acct-container .create-acct-image-container {
        display: block;
        float: left;
        height: 100%;
        width: 45%; }
        .create-acct-container .create-acct-image-container img {
          width: 100%;
          height: 100%; }
      .create-acct-container .create-acct-form-container {
        float: right;
        width: 55%;
        margin-top: 5%;
        display: flex;
        flex-direction: column; }
        .create-acct-container .create-acct-form-container h2 {
          text-align: center; }
        .create-acct-container .create-acct-form-container .name-container {
          height: 100px; }
          .create-acct-container .create-acct-form-container .name-container .first-name-container {
            width: 40%;
            float: left;
            margin-left: 35px; }
          .create-acct-container .create-acct-form-container .name-container .last-name-container {
            width: 40%;
            float: left;
            margin-left: 35px; }
        .create-acct-container .create-acct-form-container .contact-container .email-container {
          width: 40%;
          float: left;
          margin-left: 35px; }
        .create-acct-container .create-acct-form-container .contact-container .phone-number-container {
          width: 40%;
          float: left;
          margin-left: 35px; }
        .create-acct-container .create-acct-form-container .password-container {
          width: 40%;
          float: left;
          margin-left: 35px; }
        .create-acct-container .create-acct-form-container .confirm-password-container {
          width: 40%;
          float: left;
          margin-left: 35px; }
        .create-acct-container .create-acct-form-container .create-acct-button {
          width: 60%;
          margin: 0 auto; }
          .create-acct-container .create-acct-form-container .create-acct-button button {
            margin-top: 15%; } }

.my-profile-form-container {
  max-width: 1000px; }
  .my-profile-form-container h3 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    margin-bottom: 10px; }
  .my-profile-form-container label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #676C70;
    line-height: 48px; }
  .my-profile-form-container input {
    height: 41px;
    width: 100%;
    border: 1px solid #b1b5c3;
    border-radius: 5px;
    font-size: 18px;
    padding-left: 15px; }
    .my-profile-form-container input:disabled {
      background-color: #f2f2f2; }
    .my-profile-form-container input:focus {
      outline-color: #622b62; }
    .my-profile-form-container input::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #b1b5c4; }
  .my-profile-form-container button {
    margin-top: 10%;
    border-radius: 5px;
    width: 40%;
    height: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border: 0px; }
    .my-profile-form-container button:hover {
      background-color: #8e3e8e;
      cursor: pointer; }
  .my-profile-form-container .title-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 5%; }
    .my-profile-form-container .title-container .profile-icon-container {
      text-align: center; }
      .my-profile-form-container .title-container .profile-icon-container img {
        width: 96px;
        height: 96px; }
  .my-profile-form-container .my-profile-fields-container .invalid-message {
    color: #EB5757;
    font-size: 14px;
    text-align: center; }
  .my-profile-form-container .my-profile-fields-container .first-name-container {
    width: 90%;
    margin: 0 auto; }
  .my-profile-form-container .my-profile-fields-container .last-name-container {
    width: 90%;
    margin: 0 auto; }
  .my-profile-form-container .my-profile-fields-container .email-container {
    width: 90%;
    margin: 0 auto; }
  .my-profile-form-container .my-profile-fields-container .phone-number-container {
    width: 90%;
    margin: 0 auto; }
  .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px; }
    .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .update-button {
      background-color: #622b62;
      color: white;
      margin: 0 auto; }
      .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .update-button:hover {
        background-color: #8e3e8e; }
    .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .cancel-button {
      border: 1px solid #EB5757;
      color: #EB5757;
      width: 80px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: white;
      margin: 0 auto;
      margin-top: 30px; }
      .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .cancel-button:hover {
        background-color: #EB5757;
        color: white; }
    .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .change-password-button {
      background-color: white;
      border: 1px solid #622b62;
      color: #622b62; }
      .my-profile-form-container .my-profile-fields-container .my-profile-buttons-container .change-password-button:hover {
        background-color: #f2f2f2;
        cursor: pointer; }
  .my-profile-form-container .change-password-container {
    width: 90%;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin: 0 auto;
    padding-left: 20px;
    margin-top: 10%; }
    .my-profile-form-container .change-password-container input {
      width: 300px; }
    .my-profile-form-container .change-password-container button {
      background-color: #622b62;
      color: white; }
      .my-profile-form-container .change-password-container button:hover {
        background-color: #8e3e8e; }
    .my-profile-form-container .change-password-container .button-container {
      width: 90%;
      text-align: center;
      padding-bottom: 30px; }
  .my-profile-form-container .cartoon-img-container {
    margin-top: 10%; }
    .my-profile-form-container .cartoon-img-container img {
      width: 100%;
      height: 350px; }
  @media only screen and (min-width: 992px) {
    .my-profile-form-container {
      max-width: 1000px;
      margin: 0 auto; }
      .my-profile-form-container .title-container {
        text-align: center; }
      .my-profile-form-container .my-profile-fields-container {
        background-color: #f2f2f2;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 3%;
        border-radius: 5px; }
        .my-profile-form-container .my-profile-fields-container .names-row {
          display: flex; }
          .my-profile-form-container .my-profile-fields-container .names-row .first-name-container {
            margin-left: 5%;
            width: 40%; }
          .my-profile-form-container .my-profile-fields-container .names-row .last-name-container {
            margin-left: 5%;
            width: 40%; }
        .my-profile-form-container .my-profile-fields-container .communication-row {
          display: flex; }
          .my-profile-form-container .my-profile-fields-container .communication-row .email-container {
            margin-left: 5%;
            width: 40%; }
          .my-profile-form-container .my-profile-fields-container .communication-row .phone-number-container {
            margin-left: 5%;
            width: 40%; }
        .my-profile-form-container .my-profile-fields-container input {
          width: 100%; }
      .my-profile-form-container .change-password-container {
        width: 88%; }
        .my-profile-form-container .change-password-container .new-passwords-fields-container {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          width: 650px; }
        .my-profile-form-container .change-password-container h3 {
          text-align: center;
          padding-top: 3%;
          margin-bottom: 5%; }
        .my-profile-form-container .change-password-container .new-password-container {
          margin-left: 3%; }
        .my-profile-form-container .change-password-container .confirm-new-password-container {
          margin-left: 3%; }
        .my-profile-form-container .change-password-container .button-container {
          display: flex;
          margin: 0 auto;
          padding-bottom: 3%;
          padding-top: 3%; }
          .my-profile-form-container .change-password-container .button-container button {
            margin: 0 auto; }
      .my-profile-form-container .cartoon-img-container {
        margin-top: 10%;
        text-align: center; }
        .my-profile-form-container .cartoon-img-container img {
          margin: 0 auto;
          width: 550px;
          height: 350px; } }

.book-appt-form-container {
  margin-left: 5%;
  margin-right: 5%; }
  .book-appt-form-container .title-container {
    text-align: center; }
    .book-appt-form-container .title-container h3 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px; }
    .book-appt-form-container .title-container p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #676C70; }
  .book-appt-form-container .form-container .service-picker-container .nail-service-picker {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1; }
  @media only screen and (min-width: 992px) {
    .book-appt-form-container {
      max-width: 1000px;
      margin: 0 auto; }
      .book-appt-form-container .title-container {
        text-align: left;
        border-left: 6px solid #622b62;
        padding-left: 5%;
        margin-left: 5%;
        width: 60%; }
        .book-appt-form-container .title-container h3 {
          font-size: 38px;
          line-height: 60px; } }

.service-card-container {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: #f2f2f2; }
  .service-card-container .inside-card {
    width: 70%;
    height: 90%;
    margin: 0 auto;
    padding: 1px; }
    .service-card-container .inside-card .solid-line {
      border: 2px solid #622b62;
      border-radius: 5px; }
    .service-card-container .inside-card .title-container {
      text-align: left;
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      margin-top: 15px; }
    .service-card-container .inside-card .details-container {
      text-align: left; }
      .service-card-container .inside-card .details-container label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #676C70; }
    .service-card-container .inside-card .button-container {
      margin-bottom: 0px;
      margin-top: 20px; }
      .service-card-container .inside-card .button-container button {
        border-radius: 5px;
        width: 70px;
        height: 30px;
        background-color: #622b62;
        border: 0px;
        color: white;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 15px; }
        .service-card-container .inside-card .button-container button:hover {
          background-color: #8e3e8e;
          cursor: pointer; }

.collapsible-container {
  border-radius: 5px;
  overflow: hidden; }
  .collapsible-container .option-1 {
    text-align: center; }
    .collapsible-container .option-1 .collapsible-tile {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: #3f1b3f;
      cursor: pointer;
      color: white;
      font-family: "Playfair Display";
      font-style: normal; }
      .collapsible-container .option-1 .collapsible-tile::after {
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s; }
    .collapsible-container .option-1 .arrow::after {
      content: "\276F";
      color: white; }
    .collapsible-container .option-1 .rotate-arrow::after {
      content: "\276F"; }
    .collapsible-container .option-1 .rotate-arrow::after {
      transform: rotate(90deg); }
    .collapsible-container .option-1 .option-1-content {
      color: white;
      background-color: #cccccc;
      font-family: "Inter";
      font-style: normal;
      justify-content: center; }
    .collapsible-container .option-1 .hide-content {
      max-height: 0;
      padding: 0 1em;
      transition: 0.35s ease-in-out; }
    .collapsible-container .option-1 .show-content {
      max-height: 100vh;
      padding: 1em;
      transition: 0.35s ease-in-out; }

.nail-services-container {
  width: fit-content;
  margin: 0 auto; }
  .nail-services-container select {
    border: 1px solid #622b62;
    border-radius: 5px;
    background-color: #f2f2f2;
    height: 40px;
    width: 170px;
    text-align: center;
    font-family: "Inter";
    font-style: normal; }
  .nail-services-container .acrylics-container label {
    width: 80%;
    background-color: transparent;
    display: inline-block;
    color: #622b62;
    font-weight: bold;
    letter-spacing: 0.1rem; }
  .nail-services-container .dip-powders-container {
    margin-top: 10px; }
    .nail-services-container .dip-powders-container label {
      width: 80%;
      background-color: transparent;
      display: inline-block;
      color: #622b62;
      font-weight: bold;
      letter-spacing: 0.1rem; }
  .nail-services-container .manicures-container {
    margin-top: 10px; }
    .nail-services-container .manicures-container label {
      width: 80%;
      background-color: transparent;
      display: inline-block;
      color: #622b62;
      font-weight: bold;
      letter-spacing: 0.1rem; }
  .nail-services-container .nail-polish-container {
    margin-top: 10px; }
    .nail-services-container .nail-polish-container label {
      width: 80%;
      background-color: transparent;
      display: inline-block;
      color: #622b62;
      font-weight: bold;
      letter-spacing: 0.1rem; }

.stepper-custom-container {
  text-align: center; }
  .stepper-custom-container .sub-titles {
    display: flex;
    color: #622b62;
    width: fit-content;
    margin: 0 auto;
    margin-top: 50px; }
    .stepper-custom-container .sub-titles .step-1,
    .stepper-custom-container .sub-titles .step-2,
    .stepper-custom-container .sub-titles .step-3,
    .stepper-custom-container .sub-titles .step-4 {
      padding-right: 10px;
      padding-left: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      font-family: "Inter";
      font-size: 14px; }
    .stepper-custom-container .sub-titles .middle-line {
      border-right: 1px solid #622b62; }
    .stepper-custom-container .sub-titles .step-active {
      background-color: #622b62;
      color: white; }
  .stepper-custom-container .content {
    margin-bottom: 50px; }
    .stepper-custom-container .content button {
      height: 30px;
      width: 80px;
      border: 0px;
      border-radius: 5px;
      color: white;
      font-family: "Playfair Display";
      font-style: normal;
      letter-spacing: 0.1rem;
      cursor: pointer; }
    .stepper-custom-container .content .next-button {
      background-color: #622b62; }
    .stepper-custom-container .content .back-button {
      background-color: white;
      border: 1px solid #622b62;
      color: #622b62; }
    .stepper-custom-container .content .card {
      margin: 0 auto;
      margin-top: 10%;
      background-color: #f2f2f2;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
      .stepper-custom-container .content .card .title {
        height: 90px;
        background-color: #622b62;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0.2rem; }
      .stepper-custom-container .content .card button {
        margin-top: 30px;
        margin-bottom: 20px; }
      .stepper-custom-container .content .card button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666; }
    .stepper-custom-container .content .services-content p {
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      margin-left: 15px;
      margin-right: 15px;
      color: black; }
    .stepper-custom-container .content .services-container .services-collapsable {
      width: 90%;
      margin: 0 auto; }
      .stepper-custom-container .content .services-container .services-collapsable .manicures {
        margin-bottom: 20px; }
      .stepper-custom-container .content .services-container .services-collapsable .pedicures {
        margin-bottom: 20px; }
      .stepper-custom-container .content .services-container .services-collapsable .kids {
        margin-bottom: 20px; }
      .stepper-custom-container .content .services-container .services-collapsable .men {
        margin-bottom: 20px; }
      .stepper-custom-container .content .services-container .services-collapsable .soak-off {
        margin-bottom: 20px; }
    .stepper-custom-container .content .day-container button {
      margin-right: 10px; }
    .stepper-custom-container .content .day-container .date-content {
      width: fit-content;
      margin: 0 auto; }
      .stepper-custom-container .content .day-container .date-content .warning-no-appts {
        color: #EB5757;
        font-family: "Inter";
        font-size: 14px;
        margin-top: 30px; }
    .stepper-custom-container .content .payment-container .credit-card-step {
      width: 80%;
      margin: 0 auto;
      margin-top: 30px; }
    .stepper-custom-container .content .payment-container button {
      margin-right: 10px; }
    .stepper-custom-container .content .confirmation-container button {
      margin-right: 10px; }
  @media only screen and (min-width: 992px) {
    .stepper-custom-container .content {
      max-width: 900px;
      margin: 0 auto; } }

.pedicure-services-container {
  width: fit-content;
  margin: 0 auto; }
  .pedicure-services-container select {
    border: 1px solid #622b62;
    border-radius: 5px;
    background-color: #f2f2f2;
    height: 40px;
    width: 170px;
    text-align: center;
    font-family: "Inter";
    font-style: normal; }

.pedicures .pedicure-services-container label {
  width: fit-content;
  display: inline-block;
  width: 80%;
  background-color: transparent;
  color: #622b62;
  font-weight: bold;
  letter-spacing: 0.1rem; }

.date-selection-container {
  margin-top: 30px;
  display: flex;
  align-items: center; }
  .date-selection-container .day-title {
    width: 130px;
    text-align: left;
    padding-left: 30px;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal; }
  .date-selection-container input {
    border: 1px solid #622b62;
    border-radius: 5px;
    height: 40px;
    width: 170px;
    text-align: center;
    font-family: "Inter";
    font-style: normal; }
    .date-selection-container input:focus {
      outline-color: #622b62; }
    .date-selection-container input::-webkit-calendar-picker-indicator {
      width: 20px;
      height: 20px; }

select {
  color: black; }

.time-selection-container {
  margin-top: 30px;
  display: flex;
  align-items: center; }
  .time-selection-container .time-title {
    width: 130px;
    text-align: left;
    padding-left: 30px;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal; }
  .time-selection-container select {
    border-radius: 5px;
    border: 1px solid #622b62;
    height: 40px;
    width: 170px;
    text-align: center;
    font-family: "Inter";
    font-style: normal; }
    .time-selection-container select:focus {
      outline-color: #622b62; }

.payment-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;
  margin-top: 3%; }
  .payment-form-container .bold {
    font-weight: bold;
    color: black; }
  .payment-form-container .note {
    font-size: 14px;
    margin-top: 15px;
    color: black; }
  .payment-form-container .stripe-card-element {
    width: 100%;
    height: 50px;
    margin-top: 3%;
    border: 1px solid #676C70;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 50px;
    background-color: white; }
    .payment-form-container .stripe-card-element .add-cc-button {
      width: 100%;
      background-color: #622b62;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border: 1px solid #622b62; }
      .payment-form-container .stripe-card-element .add-cc-button:hover {
        cursor: pointer; }
  .payment-form-container .card-selected {
    width: 250px;
    height: 100px;
    border: 1px solid #04AA6D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #04AA6D; }
    .payment-form-container .card-selected .card-selected-title {
      margin-top: 15px;
      margin-bottom: 15px;
      color: #04AA6D; }
    .payment-form-container .card-selected .card-selected-content {
      color: #676C70; }
  .payment-form-container .saved-cards-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    width: 100%; }
    .payment-form-container .saved-cards-container .delete-card {
      background-color: white;
      border: 1px solid #EB5757;
      color: #EB5757; }
      .payment-form-container .saved-cards-container .delete-card:hover {
        background-color: #EB5757;
        color: white; }
    .payment-form-container .saved-cards-container .use-card {
      background-color: white;
      border: 1px solid #04AA6D;
      color: #04AA6D; }
      .payment-form-container .saved-cards-container .use-card:hover {
        background-color: #04AA6D;
        color: white; }
    .payment-form-container .saved-cards-container .cards-actions {
      display: flex;
      margin: auto; }
      .payment-form-container .saved-cards-container .cards-actions button {
        height: 40px;
        cursor: pointer;
        font-weight: bold; }
    .payment-form-container .saved-cards-container .payment-title {
      font-size: 18px;
      color: black;
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 15px; }
    .payment-form-container .saved-cards-container .cards-radio-buttons {
      justify-content: center;
      display: flex; }
      .payment-form-container .saved-cards-container .cards-radio-buttons .radios input {
        margin-top: 15px; }
  .payment-form-container .error-cc-message {
    color: #EB5757; }
  @media only screen and (min-width: 992px) {
    .payment-form-container .stripe-card-element {
      width: 60%; }
    .payment-form-container .saved-cards-container {
      width: 60%;
      align-items: center; } }

.confirmation-form-container {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal; }
  .confirmation-form-container p {
    margin-left: 15px;
    margin-right: 15px; }
  .confirmation-form-container .confirmation-service {
    display: flex;
    margin-top: 35px;
    text-align: left; }
    .confirmation-form-container .confirmation-service .service-title {
      width: 120px;
      color: #622b62;
      font-weight: bold;
      padding-left: 30px; }
  .confirmation-form-container .confirmation-service-container {
    width: fit-content;
    margin: 0 auto; }

.my-schedule-container {
  max-width: 1000px;
  margin: 0 auto; }
  .my-schedule-container .refresh-button {
    margin-top: 3%;
    height: 40px;
    font-family: "Playfair Display";
    font-style: normal;
    letter-spacing: 0.1rem;
    border: 1px solid #676C70;
    background-color: white;
    color: #676C70;
    border-radius: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 10px; }
    .my-schedule-container .refresh-button:hover {
      background-color: #676C70;
      color: white;
      cursor: pointer; }
  .my-schedule-container .schedule-title {
    border-left: 6px solid #622b62;
    padding-left: 5%;
    margin-left: 5%;
    margin-right: 5%; }
    .my-schedule-container .schedule-title h3 {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px; }
    .my-schedule-container .schedule-title p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #676C70; }
  @media only screen and (min-width: 992px) {
    .my-schedule-container .schedule-title {
      margin-left: 0;
      margin-bottom: 5%; }
      .my-schedule-container .schedule-title h3 {
        font-size: 38px;
        line-height: 60px; } }

.calendar-container {
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Playfair Display";
  font-style: normal;
  margin-top: 5%; }
  .calendar-container .calendar-buttons {
    margin-top: 30px;
    display: flex; }
    .calendar-container .calendar-buttons button {
      margin: 0 auto; }
  @media only screen and (min-width: 992px) {
    .calendar-container {
      margin-top: 2%; } }

.forgot-password-form-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15%;
  max-width: 1000px; }
  .forgot-password-form-container .forgot-pass-title {
    display: flex;
    width: fit-content;
    margin-top: 10%; }
    .forgot-password-form-container .forgot-pass-title label {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      white-space: nowrap; }
    .forgot-password-form-container .forgot-pass-title .password-icon-container {
      width: fit-content;
      margin-left: 5%;
      margin-top: 2%; }
      .forgot-password-form-container .forgot-pass-title .password-icon-container img {
        margin-top: -5px;
        width: 33px;
        height: 33px; }
  .forgot-password-form-container .title-description p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #676C70; }
  .forgot-password-form-container .form-container .invalid-email {
    color: #EB5757;
    font-family: "Inter";
    font-size: 14px; }
  .forgot-password-form-container .form-container .back-link-container {
    margin-top: 15%;
    display: flex; }
    .forgot-password-form-container .form-container .back-link-container img {
      width: 16px;
      height: 16px; }
    .forgot-password-form-container .form-container .back-link-container .back-link {
      margin-left: 1%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #676C70;
      text-decoration: underline;
      cursor: pointer; }
  .forgot-password-form-container .form-container input {
    padding-left: 15px;
    height: 41px;
    width: 100%;
    border: 1px solid #b1b5c3;
    border-radius: 5px;
    font-size: 18px; }
    .forgot-password-form-container .form-container input:focus {
      outline-color: #622b62; }
    .forgot-password-form-container .form-container input::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #b1b5c4; }
  .forgot-password-form-container .form-container button {
    margin-top: 10%;
    background-color: #622b62;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: white;
    border: 0px; }
    .forgot-password-form-container .form-container button:hover {
      background-color: #8e3e8e;
      cursor: pointer; }
  @media only screen and (min-width: 992px) {
    .forgot-password-form-container {
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 6%;
      margin-top: 3%; }
      .forgot-password-form-container .forgot-pass-card {
        width: 50%;
        margin: 0 auto;
        background-color: #f2f2f2;
        border-radius: 5px;
        padding: 5%; } }

.appointment-details-pop-up {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 22;
  font-family: "Playfair Display";
  font-style: normal; }
  .appointment-details-pop-up .appt-details-card-container {
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 20px);
    background-color: #622b62;
    border-radius: 15px;
    width: 390px;
    height: 180px;
    display: flex;
    margin-bottom: 50%; }
    .appointment-details-pop-up .appt-details-card-container .card-content-container {
      background-color: #f2f2f2;
      border-radius: 15px;
      width: 350px;
      height: fit-content;
      margin: 0 auto;
      margin-top: 15%;
      padding-bottom: 15%;
      position: relative; }
      .appointment-details-pop-up .appt-details-card-container .card-content-container .back-link-container {
        position: absolute;
        bottom: 80px;
        left: 30px;
        width: 100%; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .back-link-container img {
          width: 16px;
          height: 16px; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .back-link-container .back-link {
          margin-left: 1%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #676C70;
          text-decoration: underline;
          cursor: pointer;
          vertical-align: text-top; }
      .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container {
        position: absolute;
        bottom: 10px;
        left: 15px; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .middle-button {
          margin-left: 10px;
          margin-right: 10px;
          border: 1px solid #EB5757;
          color: #EB5757; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .middle-button:hover {
            background-color: #EB5757;
            color: white; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .close-button {
          border: 1px solid #676C70;
          color: #676C70; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .close-button:hover {
            background-color: #676C70;
            color: white; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .check-in-button {
          border: 1px solid #04AA6D;
          color: #04AA6D; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container .check-in-button:hover {
            background-color: #04AA6D;
            color: white; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .buttons-container button {
          border: 0;
          border-radius: 5px;
          height: 40px;
          cursor: pointer; }
      .appointment-details-pop-up .appt-details-card-container .card-content-container .card-title {
        margin-top: 10%; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .card-title h3 {
          color: black;
          border-bottom: 3px solid #622b62;
          width: fit-content;
          margin: 0 auto; }
      .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content {
        margin-top: 10%; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details {
          display: flex;
          margin-left: 10%;
          margin-bottom: 5%; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .appt-details-description-container {
            width: 50%; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .appt-details-description-container .label-description {
              color: black;
              font-weight: bold; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .label-value-container {
            width: 48%; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .label-value-container .label-value {
              color: black; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .label-value-services-container {
            width: 48%;
            height: 100px;
            overflow: scroll; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details .label-value-services-container .label-value {
              color: black; }
        .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel {
          text-align: center;
          padding-bottom: 20%;
          color: black; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel p {
            margin-left: 10px;
            margin-right: 10px; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel .no-show-button {
            border: 1px solid #EB5757;
            color: #EB5757; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel .no-show-button:hover {
              background-color: #EB5757;
              color: white; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel .confirmation-question {
            margin-top: 40px; }
          .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel button {
            border: 0;
            margin-top: 30px;
            border-radius: 5px;
            height: 40px;
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            border: 1px solid #622b62;
            color: #622b62;
            font-family: "Playfair Display"; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel button:hover {
              background-color: #622b62;
              color: white; }
            .appointment-details-pop-up .appt-details-card-container .card-content-container .card-content .appt-details-cancel button:disabled {
              background-color: #676C70;
              color: white;
              border: 0; }

.popup-animation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 22; }
  .popup-animation .loader-container {
    margin: 30% auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center; }
    .popup-animation .loader-container .loader-text {
      margin-top: 15%;
      font-family: "Playfair Display";
      font-style: normal;
      color: white;
      letter-spacing: .1rem; }
    .popup-animation .loader-container .loader {
      animation: loader-turn 1s linear infinite;
      padding: 1rem;
      max-width: 60px;
      width: 100%; }

@keyframes loader-turn {
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(720deg); } }
    .popup-animation .loader-container .loader__value {
      animation: loader-stroke 2s linear infinite;
      fill: none;
      stroke-dasharray: 63;
      stroke-dashoffset: 63;
      stroke-linecap: round;
      stroke-width: 4; }
      .popup-animation .loader-container .loader__value:nth-child(1) {
        stroke: #622b62; }
      .popup-animation .loader-container .loader__value:nth-child(2) {
        stroke: #8e3e8e;
        animation-delay: 1s; }

@keyframes loader-stroke {
  25% {
    stroke-dashoffset: 0; }
  50%,
  100% {
    stroke-dashoffset: 63; } }
  @media only screen and (min-width: 992px) {
    .popup-animation .loader-container {
      margin: 20% auto; } }

.error-popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 22;
  color: black; }
  .error-popup-container .popup-content {
    margin: 30% auto;
    width: 300px;
    overflow-wrap: break-word;
    background-color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative; }
    .error-popup-container .popup-content .error-icon-container {
      text-align: center;
      padding-top: 5%; }
    .error-popup-container .popup-content .popup-title {
      margin-bottom: 50px; }
      .error-popup-container .popup-content .popup-title h3 {
        font-family: "Inter";
        font-style: normal;
        letter-spacing: 0.05rem; }
      .error-popup-container .popup-content .popup-title p {
        font-family: "Playfair Display";
        font-style: normal;
        margin-left: 5%;
        margin-right: 5%;
        letter-spacing: 0.1rem; }
    .error-popup-container .popup-content .error-button-container {
      width: 100%;
      position: absolute;
      bottom: 0; }
      .error-popup-container .popup-content .error-button-container .error-button-dismiss {
        border: 0;
        background-color: #EB5757;
        height: 40px;
        width: 100%;
        font-family: "Inter";
        font-style: normal;
        letter-spacing: 0.05rem;
        cursor: pointer;
        color: black; }
  @media only screen and (min-width: 992px) {
    .error-popup-container .popup-content {
      margin: 15% auto;
      width: 400px; } }

.loading-bar-container {
  margin-top: 10%; }
  .loading-bar-container .loading-bar-title {
    font-family: "Playfair Display";
    font-family: "Inter"; }
  .loading-bar-container progress {
    height: 30px; }
  .loading-bar-container .loader {
    margin: 0 auto;
    background-color: #f2f2f2;
    border: 1px solid #622b62;
    width: 200px;
    height: 15px;
    border-radius: 20px; }
    .loading-bar-container .loader .circle {
      width: 40px;
      height: 15px;
      background-color: #622b62;
      border-radius: 20px;
      animation-name: roll;
      animation-duration: 0.7s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate; }

@keyframes roll {
  0% {
    transform: translateX(0px) rotate(0); }
  100% {
    transform: translateX(163px) rotate(0); } }

.success-popup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 22; }
  .success-popup-container .success-content {
    margin: 30% auto;
    width: 300px;
    background-color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative; }
    .success-popup-container .success-content .circle-container {
      position: absolute;
      margin-top: -15%;
      left: 35%; }
    .success-popup-container .success-content .success-text {
      margin-top: 15%; }
      .success-popup-container .success-content .success-text h3 {
        font-family: "Inter";
        font-style: normal;
        letter-spacing: 0.05rem; }
      .success-popup-container .success-content .success-text p {
        font-family: "Playfair Display";
        font-style: normal;
        margin-left: 5%;
        margin-right: 5%;
        letter-spacing: 0.1rem; }
    .success-popup-container .success-content .success-button-container {
      margin-top: 10%; }
      .success-popup-container .success-content .success-button-container button {
        border: 0;
        background-color: #04AA6D;
        height: 40px;
        width: 100%;
        font-family: "Inter";
        font-style: normal;
        letter-spacing: 0.05rem;
        cursor: pointer;
        color: white; }
  @media only screen and (min-width: 992px) {
    .success-popup-container .success-content {
      margin: 15% auto; } }

.must-login-first-container {
  text-align: center;
  margin-top: 15%; }
  .must-login-first-container .must-login-first-button h3 {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Inter"; }
  .must-login-first-container .must-login-first-button button {
    width: 200px;
    height: 40px;
    border: 1px solid #622b62;
    background-color: white;
    border-radius: 5px;
    font-family: "Inter";
    font-size: 16px;
    color: #622b62;
    font-weight: bold;
    letter-spacing: .05rem; }
    .must-login-first-container .must-login-first-button button:hover {
      background-color: #622b62;
      color: white;
      cursor: pointer;
      font-weight: 400; }
  .must-login-first-container .must-login-first-button .create-account-button {
    background-color: #622b62;
    color: white;
    font-weight: 400; }
    .must-login-first-container .must-login-first-button .create-account-button:hover {
      background-color: white;
      color: #622b62;
      cursor: pointer;
      font-weight: bold; }
  .must-login-first-container .log-in-img-container img {
    width: 350px;
    height: 350px; }
  @media only screen and (min-width: 992px) {
    .must-login-first-container {
      margin-top: 5%; } }

.time-off-container {
  background-color: #f2f2f2;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 5px; }
  .time-off-container button {
    font-family: "Inter"; }
  .time-off-container input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #622b62; }
  .time-off-container select {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #622b62; }
  .time-off-container .time-off-title {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    padding-left: 10px;
    border-bottom: 3px solid #622b62; }
    .time-off-container .time-off-title h3 {
      color: black; }
    .time-off-container .time-off-title img {
      margin-left: 30px;
      margin-top: 7px;
      width: 50px;
      height: 50px; }
  .time-off-container .time-off-error {
    margin-top: 20px;
    color: #EB5757;
    font-size: 14px;
    text-align: center;
    font-family: "Inter"; }
  .time-off-container .days-selected-display {
    margin-top: 30px; }
    .time-off-container .days-selected-display table {
      margin: 0 auto;
      border: 1px solid #622b62;
      border-radius: 5px;
      width: 350px;
      color: black; }
      .time-off-container .days-selected-display table th {
        border-bottom: 1px solid #622b62;
        width: 50px; }
      .time-off-container .days-selected-display table td {
        text-align: center; }
        .time-off-container .days-selected-display table td img {
          width: 20px;
          height: 20px; }
          .time-off-container .days-selected-display table td img:hover {
            cursor: pointer; }
  .time-off-container .time-off-form {
    color: black;
    display: flex;
    margin-top: 30px; }
    .time-off-container .time-off-form .time-off-field-container {
      margin: 0 auto; }
  .time-off-container .time-off-button-container .add-button {
    background-color: white;
    border: 1px solid #04AA6D;
    border-radius: 5px;
    color: #04AA6D;
    height: 40px;
    width: 50px;
    margin-top: 20px; }
    .time-off-container .time-off-button-container .add-button:hover {
      cursor: pointer;
      background-color: #04AA6D;
      color: white; }
  .time-off-container .time-off-buttons {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 15px; }
    .time-off-container .time-off-buttons .submit-button {
      background-color: white;
      border: 1px solid #04AA6D;
      border-radius: 5px;
      color: #04AA6D;
      height: 40px;
      margin-right: 50px;
      width: 130px; }
      .time-off-container .time-off-buttons .submit-button:hover {
        cursor: pointer;
        background-color: #04AA6D;
        color: white; }
    .time-off-container .time-off-buttons .cancel-button {
      background-color: white;
      border: 1px solid #EB5757;
      color: #EB5757;
      border-radius: 5px;
      height: 40px;
      width: 130px; }
      .time-off-container .time-off-buttons .cancel-button:hover {
        background-color: #EB5757;
        color: white;
        cursor: pointer; }
  @media only screen and (min-width: 992px) {
    .time-off-container {
      width: 65%; }
      .time-off-container .days-selected-display table {
        width: 80%; }
      .time-off-container label {
        margin-bottom: 10px; }
      .time-off-container .time-off-field-container {
        display: flex;
        flex-direction: column;
        text-align: center; }
      .time-off-container .time-off-button-container {
        margin-right: 30px; }
        .time-off-container .time-off-button-container .add-button {
          margin-top: 30px; } }

.existing-appt-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 22;
  overflow: scroll; }
  .existing-appt-container .existing-appoint-content {
    margin: 30% auto;
    width: 300px;
    background-color: #f2f2f2;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative; }
    .existing-appt-container .existing-appoint-content .image-container {
      position: absolute;
      margin-top: -15%;
      width: 100%;
      text-align: center; }
    .existing-appt-container .existing-appoint-content .existing-appts {
      margin-top: 60px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      height: fit-content; }
      .existing-appt-container .existing-appoint-content .existing-appts .warning-text {
        color: #EB5757;
        font-family: "Inter";
        font-size: 14px; }
      .existing-appt-container .existing-appoint-content .existing-appts .success-text {
        color: #04AA6D;
        font-family: "Inter";
        font-size: 14px; }
      .existing-appt-container .existing-appoint-content .existing-appts .appointment-information {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 80%;
        background-color: #676C70;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 20px;
        color: black; }
        .existing-appt-container .existing-appoint-content .existing-appts .appointment-information .field-column {
          display: flex;
          flex-direction: column;
          width: 80%;
          margin: 0 auto;
          margin-bottom: 20px; }
          .existing-appt-container .existing-appoint-content .existing-appts .appointment-information .field-column .title {
            border-bottom: 1px solid black;
            margin-bottom: 10px; }
    .existing-appt-container .existing-appoint-content .existing-appt-buttons {
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: center; }
      .existing-appt-container .existing-appoint-content .existing-appt-buttons button {
        border: 1px solid #EB5757;
        border-radius: 5px;
        width: 80px;
        height: 40px;
        color: #EB5757;
        font-family: "Inter";
        font-size: 14px; }
        .existing-appt-container .existing-appoint-content .existing-appt-buttons button:hover {
          background-color: #EB5757;
          color: white; }
  @media only screen and (min-width: 992px) {
    .existing-appt-container .existing-appoint-content {
      margin: 10% auto;
      width: 800px; }
      .existing-appt-container .existing-appoint-content .image-container {
        margin-top: -8%; }
      .existing-appt-container .existing-appoint-content .existing-appts .appointment-information {
        text-align: left;
        flex-direction: row; }
        .existing-appt-container .existing-appoint-content .existing-appts .appointment-information .field-column {
          width: 40%;
          margin: 0;
          margin-right: 10px; } }

.verification-email-form-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15%;
  max-width: 1000px; }
  .verification-email-form-container .resend-verification-title {
    display: flex;
    margin-top: 10%;
    word-wrap: break-word; }
    .verification-email-form-container .resend-verification-title label {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 700;
      font-size: 27px; }
    .verification-email-form-container .resend-verification-title .email-icon-container {
      width: fit-content;
      margin-left: 5%;
      margin-top: 2%; }
      .verification-email-form-container .resend-verification-title .email-icon-container img {
        margin-top: -5px;
        width: 33px;
        height: 33px; }
  .verification-email-form-container .title-description p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #676C70; }
  .verification-email-form-container .form-container .invalid-email {
    color: #EB5757;
    font-family: "Inter";
    font-size: 14px; }
  .verification-email-form-container .form-container .back-link-container {
    margin-top: 15%;
    display: flex; }
    .verification-email-form-container .form-container .back-link-container img {
      width: 16px;
      height: 16px; }
    .verification-email-form-container .form-container .back-link-container .back-link {
      margin-left: 1%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #676C70;
      text-decoration: underline;
      cursor: pointer; }
  .verification-email-form-container .form-container input {
    height: 41px;
    width: 100%;
    border: 1px solid #b1b5c3;
    border-radius: 5px;
    font-size: 18px; }
    .verification-email-form-container .form-container input:focus {
      outline-color: #622b62; }
    .verification-email-form-container .form-container input::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #b1b5c4;
      padding-left: 5%; }
  .verification-email-form-container .form-container button {
    margin-top: 10%;
    background-color: #622b62;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: white;
    border: 0px; }
    .verification-email-form-container .form-container button:hover {
      background-color: #8e3e8e;
      cursor: pointer; }
  @media only screen and (min-width: 992px) {
    .verification-email-form-container {
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 6%;
      margin-top: 3%; }
      .verification-email-form-container .forgot-pass-card {
        width: 50%;
        margin: 0 auto;
        background-color: #f2f2f2;
        border-radius: 5px;
        padding: 5%; } }

.credit-card-container {
  width: 99%;
  border: 1px solid #676C70;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 30px; }
  .credit-card-container .card-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black; }
    .credit-card-container .card-info-container span {
      font-family: "Playfair Display";
      font-family: "Inter", sans-serif; }
    .credit-card-container .card-info-container .card-left-content {
      width: fit-content;
      margin-left: 10px; }
      .credit-card-container .card-info-container .card-left-content c
span {
        margin-left: 10px;
        font-family: "Inter", sans-serif; }
      .credit-card-container .card-info-container .card-left-content img {
        width: 44px;
        vertical-align: middle; }
    .credit-card-container .card-info-container .card-right-content {
      margin-left: 45px;
      display: flex;
      height: 40px;
      width: fit-content;
      align-items: center; }
  .credit-card-container .delete-card-container button {
    height: 40px;
    width: 120px; }
  .credit-card-container .delete-card-container .select-card-button {
    background-color: white;
    color: #04AA6D;
    border: 1px solid #04AA6D; }
    .credit-card-container .delete-card-container .select-card-button:hover {
      background-color: #04AA6D;
      color: white; }
  .credit-card-container .delete-card-container .delete-card-button {
    background-color: white;
    color: #EB5757;
    border: 1px solid #EB5757; }
    .credit-card-container .delete-card-container .delete-card-button:hover {
      background-color: #EB5757;
      color: white; }
  @media only screen and (min-width: 992px) {
    .credit-card-container {
      margin-right: 15px; } }

.appointment-container {
  width: 300px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  color: #676C70;
  font-family: "Playfair Display"; }
  .appointment-container .bold {
    font-weight: bold; }
  .appointment-container .small-font {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px; }
  .appointment-container .appointment-details-container {
    width: 70%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: left;
    margin-top: 10px; }
    .appointment-container .appointment-details-container .appointment-details {
      display: flex;
      flex-direction: column; }
    .appointment-container .appointment-details-container .appointment-cancel {
      background-color: #EB5757;
      border: 0;
      color: white;
      width: 70px;
      height: 25px;
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
  .appointment-container .appointment-date {
    width: 30%;
    display: flex;
    align-items: center;
    font-size: 12px;
    text-align: center; }
    .appointment-container .appointment-date .date-details {
      width: 100%;
      border-left: 1px solid #cccccc;
      display: flex;
      flex-direction: column; }
      .appointment-container .appointment-date .date-details .big-font {
        font-size: 24px; }

.appointments-display-container {
  font-family: "Inter";
  font-size: 14px;
  text-align: center;
  margin-bottom: 100px; }
  .appointments-display-container .appointments-title {
    text-decoration: underline; }
  .appointments-display-container .future-appointments {
    margin-top: 30px; }
  .appointments-display-container .passed-appointments {
    margin-top: 30px; }
  .appointments-display-container .no-appointments-container {
    margin-top: 60px;
    font-size: 24px; }

.kids-services-container {
  width: fit-content;
  margin: 0 auto; }
  .kids-services-container .kid-services {
    margin-bottom: 15px; }
    .kids-services-container .kid-services select {
      border: 1px solid #622b62;
      border-radius: 5px;
      background-color: #f2f2f2;
      height: 40px;
      width: 170px;
      text-align: center;
      font-family: "Inter";
      font-style: normal; }

.kids .kids-services-container label {
  width: fit-content;
  display: inline-block;
  width: 80%;
  background-color: transparent;
  color: #622b62;
  font-weight: bold;
  letter-spacing: 0.1rem; }

.zelle-container {
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px; }
  .zelle-container .zelle-intro {
    color: black; }
  .zelle-container .instructions-container {
    width: fit-content;
    color: black;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center; }
    .zelle-container .instructions-container ol {
      text-align: left; }
  .zelle-container .zelle-confimation {
    width: 100%;
    border-radius: 5px;
    height: 25px;
    border: 1px solid black;
    margin-top: 15px; }

.men-services-container {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .men-services-container .manicure-men-services {
    display: flex;
    margin-bottom: 15px; }
    .men-services-container .manicure-men-services .men-service-button .men-add-button {
      margin-top: 25px;
      vertical-align: middle;
      margin-left: 15px;
      background-color: white;
      color: #04AA6D;
      border: 1px solid #04AA6D;
      cursor: pointer; }
      .men-services-container .manicure-men-services .men-service-button .men-add-button:hover {
        background-color: #04AA6D;
        color: white; }
    .men-services-container .manicure-men-services .men-services {
      display: flex;
      flex-direction: column; }
      .men-services-container .manicure-men-services .men-services select {
        border: 1px solid #622b62;
        border-radius: 5px;
        background-color: #f2f2f2;
        height: 40px;
        width: 170px;
        text-align: center;
        font-family: "Inter";
        font-style: normal; }
      .men-services-container .manicure-men-services .men-services label {
        margin: 0 auto;
        width: fit-content;
        display: inline-block;
        background-color: transparent;
        color: #622b62;
        font-weight: bold;
        letter-spacing: 0.1rem; }
  .men-services-container .added-list-container span {
    width: fit-content;
    display: inline-block;
    width: 80%;
    background-color: transparent;
    color: #622b62;
    font-weight: bold;
    letter-spacing: 0.1rem; }
  .men-services-container .added-list-container table {
    margin: 0 auto;
    margin-top: 15px;
    border: 1px solid black;
    border-radius: 5px;
    width: 250px; }
  .men-services-container .added-list-container tr {
    border-bottom: 1px solid black; }
  .men-services-container .added-list-container th {
    color: black; }
    .men-services-container .added-list-container th img {
      width: 28px;
      height: 28px; }
      .men-services-container .added-list-container th img:hover {
        cursor: pointer; }

.soak-off-services-container {
  width: fit-content;
  margin: 0 auto; }
  .soak-off-services-container select {
    border: 1px solid #622b62;
    border-radius: 5px;
    background-color: #f2f2f2;
    height: 40px;
    width: 170px;
    text-align: center;
    font-family: "Inter";
    font-style: normal; }

.soak-off .soak-off-services-container label {
  width: fit-content;
  display: inline-block;
  width: 80%;
  background-color: transparent;
  color: #622b62;
  font-weight: bold;
  letter-spacing: 0.1rem; }

.credit-card {
  width: 80%;
  height: 45px;
  display: flex;
  margin-left: 15px;
  margin-bottom: 15px;
  border-radius: 5px; }
  .credit-card .credit-card-info {
    padding-left: 10px;
    width: 70%;
    margin: auto; }
    .credit-card .credit-card-info .info-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      vertical-align: middle;
      height: 70%;
      vertical-align: middle; }
      .credit-card .credit-card-info .info-container .card-number {
        font-size: 12px; }
      .credit-card .credit-card-info .info-container .card-expiration {
        font-size: 10px; }
  .credit-card .credit-card-logo {
    margin: auto; }
    .credit-card .credit-card-logo img {
      height: 46px;
      width: 46px; }
  @media only screen and (min-width: 992px) {
    .credit-card {
      width: 100%; } }

.add-on-services-container {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .add-on-services-container .add-on-services {
    display: flex;
    margin-bottom: 15px; }
    .add-on-services-container .add-on-services label {
      width: fit-content;
      display: inline-block;
      width: 80%;
      background-color: transparent;
      color: #622b62;
      font-weight: bold;
      letter-spacing: 0.1rem; }
    .add-on-services-container .add-on-services .add-on-select select {
      border: 1px solid #622b62;
      border-radius: 5px;
      background-color: #f2f2f2;
      height: 40px;
      width: 170px;
      text-align: center;
      font-family: "Inter";
      font-style: normal; }
    .add-on-services-container .add-on-services .add-on-button-container .add-on-add-button {
      margin-top: 25px;
      vertical-align: middle;
      margin-left: 15px;
      background-color: white;
      color: #04AA6D;
      border: 1px solid #04AA6D;
      cursor: pointer; }
      .add-on-services-container .add-on-services .add-on-button-container .add-on-add-button:hover {
        background-color: #04AA6D;
        color: white; }
  .add-on-services-container .added-list-container span {
    width: fit-content;
    display: inline-block;
    width: 80%;
    background-color: transparent;
    color: #622b62;
    font-weight: bold;
    letter-spacing: 0.1rem; }
  .add-on-services-container .added-list-container table {
    margin: 0 auto;
    margin-top: 15px;
    border: 1px solid black;
    border-radius: 5px;
    width: 250px; }
  .add-on-services-container .added-list-container tr {
    border-bottom: 1px solid black; }
  .add-on-services-container .added-list-container th {
    color: black; }
    .add-on-services-container .added-list-container th img {
      width: 28px;
      height: 28px; }
      .add-on-services-container .added-list-container th img:hover {
        cursor: pointer; }

.title-card-container {
  width: 330px;
  height: 75px;
  background-color: #622b62;
  border-radius: 5px;
  text-align: center;
  display: table;
  margin: 0 auto;
  margin-top: 30px; }
  .title-card-container span {
    display: table-cell;
    vertical-align: middle;
    color: white;
    font-size: 24px;
    letter-spacing: 0.09rem;
    font-family: "Playfair Display";
    font-style: normal; }
  @media only screen and (min-width: 992px) {
    .title-card-container {
      height: 100px;
      width: 1000px; } }

.services-offered-page .services-offered-container {
  margin-top: 30px; }
  @media only screen and (min-width: 992px) {
    .services-offered-page .services-offered-container {
      max-width: 1100px;
      margin: 0 auto;
      margin-top: 50px; } }

.offered-cards-container .service-offered-card {
  margin: 0 auto;
  width: 330px;
  border-radius: 5px;
  letter-spacing: 0.09rem;
  font-family: "Inter";
  font-style: normal;
  border: 1px solid #676C70;
  margin-top: 15px;
  border-top: 5px solid #8e3e8e; }
  .offered-cards-container .service-offered-card .title {
    font-size: 24px;
    color: #622b62;
    margin-top: 15px;
    margin-left: 20px; }
  .offered-cards-container .service-offered-card .price {
    font-size: 24px;
    color: #622b62;
    margin-left: 20px;
    margin-top: 10px; }
  .offered-cards-container .service-offered-card .description {
    color: #676C70;
    font-size: 18px;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 20px; }

@media only screen and (min-width: 992px) {
  .offered-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px; } }
